import { Component, Input } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-consultant-list',
  templateUrl: './consultant-list.component.html',
  styleUrls: ['./consultant-list.component.css']
})
export class ConsultantListComponent {

  language: string|undefined;

  constructor(private languageService: LanguageService){
    this.language = languageService.getLanguage();
  }

  ngOnInit(): void {
    let serviceLanguage:string|undefined = this.languageService.getLanguage();
    if(serviceLanguage !== undefined) {
      this.language = serviceLanguage;
    } else {
      this.language = "en";
    }
  }


  ngDoCheck(): void {
    this.language = this.languageService.getLanguage();
  }


}
