<!-- About Company -->
<section>
    <div *ngIf="language=='fi'" class="px-3 px-md-5">
        <h1>Yrityksen konsultit</h1>
        <p>
            Alla esitellään yrityksen konsultit.
        </p>
    </div>

    <div class="px-3 px-md-5" *ngIf="language=='sv'">
        <h1>Företagets konsulter</h1>
        <p>
            På sidan presenteras företaget konsulter.
        </p>
    </div>

    <div class="px-3 px-md-5" *ngIf="language=='en'">
        <h1>Company consultants</h1>
        <p>
            On this page are presented the company's consultants.
        </p>
    </div>
</section>
<section>
    <div class="container  px-3 px-md-5">
    <div class="row">
        <h2  class="px-1 csl-bg-primary">Jani Ruotsalainen</h2>
    </div>
    <div class="row ">
        <div class="col-12 col-md-2 text-center">
            <img src="assets/jani-3.png" [attr.alt]="language==='fi'?'Janin kuva':(language==='sv'?'Janis bild':'Picture of Jani')" class="csl-portrait"/>
        </div>
        <div class="col-12 col-md-10">
            <h3 class="px-1 csl-bg-secondary">
                <span  *ngIf="language=='en'">Summary</span>
                <span  *ngIf="language=='fi'">Yhteenveto</span>
                <span  *ngIf="language=='sv'">Sammanfattning</span>
            </h3>
            <p *ngIf="language=='en'">
                Jani has a long work history in the software industry spanning soon 30 years. He has worked on several public sector projects throughout his career in developer 
                or architect roles. He has a long history in Identity and Access Management having worked at a IAM-specialist company for 15 years. Jani has also worked on 
                projects for the Tax Administration, Digital and Population Data Service Agency, and Traficom. He also has worked as a consultant for the private sector, 
                among other things with mobile applications and back-end integrations to external systems.
            </p>
            <p *ngIf="language=='fi'">
                Janilla on pitkä historia IT-alalla, joka lähenee jo 30 vuotta. Hän on työskennellyt useammassa julkisen sektorin projektissa uransa aikana kehittäjän tai 
                sovellusarkkitehdin roolissa. Hänellä on on pitkä historia Identiteetin- ja pääsynhallinnasta (IAM) oltuaan töissä alaan erikoistuneessa yrityksessä 15 vuotta. 
                Jani on myös työskennellyt projekteissa Verohallinnolla, Digi- ja väestötietovirastossa sekä Traficomilla. Hän on myös työskennellyt konsulttina yksityisellä 
                sektorilla, esimerkiksi mobiilisovellusten ja taustajärjestelmien integroinnissa.
            </p>
            <p *ngIf="language=='sv'">
                Jani har en lång arbetshistoria i mjukvaru-branchen, som närmar sig 30 år. Han har jobbat på flera projekter inom offentliga sektorn under sin karriår i roller 
                såsom utvecklare eller mjukvaroarkitekt. Han har också en lång historia inom Identitets- och åtkomsthantering (IAM), efter att ha jobbat 15 år i ett bolag som 
                specialiserat sig i IAM. Jani har jobbat på projekter till Skatteförvaltingen, Myndigheten för digitalisering och befolkningsdata och Traficom. Han har också arbetshistoria
                som konsult för privata sektorin, t.ex. med mobilapplicationer och back-end integrationer till utomstående system.
            </p>                        
            <h3 class="px-1 csl-bg-secondary">
                <span  *ngIf="language=='en'">Roles</span>
                <span  *ngIf="language=='fi'">Roolit</span>
                <span  *ngIf="language=='sv'">Roller</span>
            </h3>
            <ul *ngIf="language=='en'">
                <li>Software developmen consultant</li>
                <li>CEO - Krendo Consulting Ltd</li>
            </ul>
            <ul *ngIf="language=='fi'">
                <li>Ohjelmistokehityskonsultti</li>
                <li>TJ - Krendo Consulting Ltd</li>
            </ul>
            <ul *ngIf="language=='sv'">
                <li>Konsult för mjukvaruutveckling</li>
                <li>VD - Krendo Consulting Ltd</li>
            </ul>            
            <h3 class="px-1 csl-bg-secondary">
                <span  *ngIf="language=='en'">Project availability</span>
                <span  *ngIf="language=='fi'">Projektisaatavuus</span>
                <span  *ngIf="language=='sv'">Projekt tillgänglighet</span>
            </h3>
            <p *ngIf="language=='en'">Available for customer projects.</p>
            <p *ngIf="language=='fi'">Saataville asiakasprojekteihin.</p>
            <p *ngIf="language=='sv'">Tillgänglig till kundprojekt.</p>
        </div>
    </div>
</div>
    
</section>