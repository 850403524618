<ng-template #content let-modal>
	<div class="modal-header" style="background-color: orange;">
		<a href="#" class="navbar-brand mr-auto">
            <img src="/assets/logo.png" alt="Krendo-Consulting" class="img d-none d-xl-block" height="36" width="200"/>
            <img src="/assets/logo_compact.png" alt="Krendo-Consulting" class="img d-block d-xl-none" height="36" width="120"/>			
        </a>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="container-fluid py-3 card">
			<!-- start temp-->  
			<h5 class="card-title"  *ngIf="language=='fi'">Yhteystiedot</h5>
			<h5 class="card-title"  *ngIf="language=='en'">Contact details</h5>
			<h5 class="card-title"  *ngIf="language=='sv'">Kontaktuppgifter</h5>

			<div class="row">
				<div *ngIf="language=='fi'" class="col-3 card-title fw-bold">Puhelin:</div>
				<div *ngIf="language=='en' || language==undefined" class="col-3 card-title fw-bold">Telephone:</div>
				<div *ngIf="language=='sv'"  class="col-3 card-title fw-bold">Telefon:</div>
				<div class="col-9 card-text" *ngIf="contact !== undefined">{{ contact.telephone }}</div>
			</div>
			<div class="row">
				<div *ngIf="language=='fi'" class="col-3 card-title fw-bold">s-posti:</div>
				<div *ngIf="language=='en' || language==undefined" class="col-3 card-title fw-bold">e-mail:</div>
				<div *ngIf="language=='sv'"  class="col-3 card-title fw-bold">e-post:</div>
				<div class="col-9 card-text" *ngIf="contact !== undefined">{{ contact.email }}</div>
			</div>
			<div class="row">
				<div *ngIf="language=='fi'" class="col-3 card-title fw-bold">Osoite:</div>
				<div *ngIf="language=='en' || language==undefined" class="col-3 card-title fw-bold">Address:</div>
				<div *ngIf="language=='sv'"  class="col-3 card-title fw-bold">Adress:</div>
				<div class="col-9 card-text" *ngIf="contact !== undefined">{{ contact.address.streetAddress }}, {{ contact.address.postalCode }}, {{ contact.address.city }}</div>
			</div>
			<div class="row">
				<div *ngIf="language=='fi'" class="col-3 card-title fw-bold">Maa:</div>
				<div *ngIf="language=='en' || language==undefined" class="col-3 card-title fw-bold">State:</div>
				<div *ngIf="language=='sv'"  class="col-3 card-title fw-bold">Land:</div>
				<div class="col-9 card-text" *ngIf="contact !== undefined">{{ contact.address.state }}</div>
			</div>

			<!-- end temp -->
			  <div class="row mt-3" *ngIf="contact === undefined">
				<div class="col-sm-12 mb-2">
				  <re-captcha 
				  		id="recaptcha" 
						name="recaptcha" 
						#recaptcha="ngModel" 
						[(ngModel)]="token" 
						(resolved)="resolved($event)"
						(errored)="errored($event)"
					required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
				    <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
					<div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
				  </div>
				</div>
			  </div>
		  </div>
	</div>
</ng-template>
<div class="navbar-light">
    <button class="btn-lg btn bi-telephone" (click)="open(content)" ></button>
</div>






