<div class="container">
    <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn" id="langDropdown" ngbDropdownToggle>
            <i class="flag-icon flag-icon-{{ selectedCountryCode }}" style="height: 18pt; width: 18pt;"></i>
        </button>
        <ul ngbDropdownMenu class="dropdown-menu-end" aria-labelledby="langDropdown">
            <li *ngFor="let item  of getSupportedCountryCodes(); let i=index;">
                <a (click)="setSelectedCountryCode(item)" class="dropdown-item btn">
                    <i class="dropdown-item flag-icon flag-icon-{{ item }}"></i> {{ getLanguageNameForCode(item) }}
                    <i *ngIf="isSelectedCountryCode(item)" class="fa fa-check text-success ms-2"></i>
                </a>
            </li>
        </ul>
    </div>

</div>