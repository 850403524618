import { Component, DoCheck, Input, OnChanges, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.css']
})
export class CompanyInfoComponent implements OnInit, DoCheck {
  
  	language: string|undefined;

  constructor(private languageService: LanguageService){
    this.language = languageService.getLanguage();
  }

  ngOnInit(): void {
    let serviceLanguage:string|undefined = this.languageService.getLanguage();
    if(serviceLanguage !== undefined) {
      this.language = serviceLanguage;
    } else {
      this.language = "en";
    }
  }


  ngDoCheck(): void {
    this.language = this.languageService.getLanguage();
  }



}
