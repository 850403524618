import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment.development';
import { ContactService } from './contact/services/contact.service';
import { RestDataSource } from './contact/services/rest.datasource';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { ConsultantListComponent } from './consultant-list/consultant-list.component';
import { LanguageService } from './language.service';

@NgModule({
  declarations: [
    AppComponent,
    LanguageSelectorComponent,
    ContactModalComponent,
    CompanyInfoComponent,
    ConsultantListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings
    }, 
    ContactService,
    RestDataSource,
    LanguageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
