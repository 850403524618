import { Injectable, OnInit } from '@angular/core';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private language: string|undefined;

  constructor() { 
    this.language = LanguageSelectorComponent.getDefaultLanguage();
  }

  setLanguage = (lang: string|undefined) : void => {
    this.language = lang;
  }

  getLanguage = (): string|undefined => {
    return this.language;
  }

}
