import { Component, Input, OnInit } from '@angular/core';
import { LanguageSelectorComponent } from './language-selector/language-selector.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title: string = 'www.krendo-consulting.com';
  @Input()
  language: string|undefined;

  ngOnInit(): void {
    this.language =  LanguageSelectorComponent.getDefaultLanguage();
  }

  changeLanguage = (lang: string) => {
    this.language = lang;
  }

}
