import { Injectable } from '@angular/core';
import { Contact } from '../model/contact.model';
import { Observable } from 'rxjs';
import { RestDataSource } from './rest.datasource'; 
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private rest: RestDataSource) { }

  getContactDetails = (token: string): Observable<Contact> => this.rest.getContactDetails(token)
}
