import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { ConsultantListComponent } from './consultant-list/consultant-list.component';


const routes: Routes = [
  {path: "home", component: CompanyInfoComponent},
  {path: "consultants", component: ConsultantListComponent},
  {path: "", redirectTo: "/home", pathMatch: "full"},
  {path: "**", redirectTo: "/home", pathMatch: "full"} /* TODO: PageNotFoundComponent*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
