
<nav class="navbar navbar-expand-lg navbar-light orange fixed-top" style="background-color: orange;">
    <div class="nav-item dropdown ms-xl-2 ms-lg-1" ngbDropdown>
        <a class="nav-link" id="navbarScrollingDropdown" role="button" data-bs-toggle="collapse" aria-expanded="false" ngbDropdownToggle>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
              </svg>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="navbarScrollingDropdown" style="background-color: rgb(255, 166, 0)">
            <li ngbDropdownItem><a class="dropdown-item" routerLink="home">Home</a></li>
            <li ngbDropdownItem><a class="dropdown-item"  routerLink="consultants">Consultants</a></li>
        </ul>
    </div>
    <a href="#" class="navbar-brand me-auto ps-sm-3">
        <img src="/assets/logo.png" alt="Krendo-Consulting Logo" class="img d-none d-xl-block" height="36" width="200" />
        <img src="/assets/logo_compact.png" alt="Krendo-Consulting Logo" class="img d-block d-xl-none" height="40" width="90"/>
    </a>

    <div class="navbar-light ms-auto">
        <app-contact-modal></app-contact-modal>
    </div>

    <div class="navbar-light">
        <app-language-selector></app-language-selector>
    </div>
</nav>
<div class="mt-5 container">
<router-outlet></router-outlet>
</div>

