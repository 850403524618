import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { Observable, firstValueFrom } from 'rxjs';
import { Contact } from '../contact/model/contact.model';
import { ContactService } from '../contact/services/contact.service';
import { LanguageService } from '../language.service';

@Component({
	selector: 'app-contact-modal',
	templateUrl: './contact-modal.component.html',
	styleUrls: ['./contact-modal.component.css']
})
export class ContactModalComponent {
	closeResult = '';
	token: string | undefined;
	contact: Contact | undefined;
	@Input()
	language: string | undefined;

	constructor(private modalService: NgbModal, private contactService: ContactService, private languageService: LanguageService) {
		this.token = undefined;
		this.contact = undefined;
		this.language = languageService.getLanguage();
	}


	ngOnInit(): void {
		let serviceLanguage: string | undefined = this.languageService.getLanguage();
		if (serviceLanguage !== undefined) {
			this.language = serviceLanguage;
		} else {
			this.language = "en";
		}
	}


	ngDoCheck(): void {
		this.language = this.languageService.getLanguage();
	}

	open(content: any) {
		console.log("Hello fro modal");
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	resolved = (captchaResponse: string) => {
		console.log("CAPTCHA: " + captchaResponse);
		firstValueFrom(this.contactService.getContactDetails(captchaResponse))
			.then(contact => { this.contact = contact; return contact })
			.then(contact => console.log(JSON.stringify(contact)));
	}

	errored = (captchaError: RecaptchaErrorParameters) => {

		console.log("CAPTCHA ERROR: " + JSON.stringify(captchaError));
	}

	public send(form: NgForm): void {
		if (form.invalid) {
			for (const control of Object.keys(form.controls)) {
				form.controls[control].markAsTouched();
			}
			console.log("Invalid form");
			return;
		}

		console.log(`Token [${this.token}] generated`);
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}