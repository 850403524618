import { Component, EventEmitter, Input, Output, OnInit, ApplicationRef } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {

  private supportedCountryCodes: Array<string> = ["gb", "fi", "se"];
  
  @Input()
  selectedCountryCode: string|undefined;

  constructor(private languageService: LanguageService, private appRef: ApplicationRef) {
  }

  ngOnInit(){
    this.selectedCountryCode = LanguageSelectorComponent.getDefaultCountryCode();
  }
  
  getSupportedCountryCodes = (): Array<string> => {
    return this.supportedCountryCodes;
  }

  static getDefaultCountryCode = () => {
    return LanguageSelectorComponent.getCountryCodeForLanguage(LanguageSelectorComponent.getDefaultLanguage());
  }

  public static getDefaultLanguage = ():string => {
    return navigator.language.split("-")[0];
  }

  getLanguageNameForCode = (code: string): string => {
    switch(code) {
      case "fi":
        return "Suomeksi";
      case "se":
        return "På Svenska";
      default:
        return "In English";
    }
  }

  static getCountryCodeForLanguage = (lc: string|undefined): string => {
    switch (lc) {
      case "fi": return "fi";
      case "sv": return "se";
      case "en": return "gb";
      default: return "gb";
    }
  }
  getLanguageForCountryCode = (cc: string): string => {
    switch (cc) {
      case "fi": return "fi";
      case "se": return "sv";
      case "gb": return "en";
      default: return "en";
    }
  }

  isSelectedCountryCode = (code: string) => {
    return code == LanguageSelectorComponent.getCountryCodeForLanguage(this.languageService.getLanguage());
  }

  setSelectedCountryCode = (code: string) => {
    if (this.supportedCountryCodes.indexOf(code) >= 0) {
      this.selectedCountryCode = code;
      this.languageService.setLanguage(this.getLanguageForCountryCode(code));
      this.appRef.tick();
    }
  }

}
