import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Contact } from '../model/contact.model';
import { Observable } from "rxjs";

@Injectable()
export class RestDataSource {

    private baseUrl: string;

    constructor (private http: HttpClient) {
        /*TODO: add exception for development, because frontend is then in different port*/
        this.baseUrl = `${location.protocol}//${location.hostname}:${location.port}/contact`;
    }

    getContactDetails = (token: string): Observable<Contact> => {
        return this.http.get<Contact>(this.baseUrl + "/contact-info?captcha-token=" + token);
    }

}
