<!-- About Company -->
<section class="mt-5"></section>
<section>
    <div *ngIf="language=='fi'" class="px-3 px-md-5">
        <h1>Tietoa yrityksestä</h1>
        <p>
            Krendo Consulting Oy on ohjelmistokehitykseen erikoistuva yritys. Ohjelmistokonsultointialalla on tyypillistä, että
            konsulttien takana on suuri organisaatio ja usein on lukuisia välikäsiä asiakkaan ja konsultin välillä. Krendo Consulting
            toisaalta, on pieni yritys, jossa suuren organisaation kustannustaakka ei seuraa mukana loppuasiakkaalle ja tästä johtuen
            konsultointia on mahdollista tehdä kustannustehokkaasti.
        </p>
        <p>
            Krendo Consulting Oy suosii avoimen lähdekoodin palveluita ja yhteisöversioita palvelinohjelmistoista, sekä pyrkii kohdentamaan
            pilvipalvelut sellaisille palveluntarjoajille, jotka tarjoavat laadukkaan, mutta kustannustehokkaimmat palvelut. 
            Ohjelmistojen provisiointiin rakennetaan automaatiota joka helpottaa järjestelmän siirtämisen pilvipalveluntarjoajalta toiselle.
        </p>
    </div>

    <div class="px-3 px-md-5" *ngIf="language=='sv'">
        <h1>Om företaget</h1>
        <p>
            Krendo Consulting AB är ett företag som specialiserar sig på programvaruutveckling. uförtiden är det typiskt att konsulterna kommer 
            från stora bolag och att det finns flera mellanhänder mellan kunden och konsulten. Krendo Consulting AB, å andra sidan, är ett litet 
            bolag som har mindre fasta kostnader än större bolag. Därför kan vi erbjuda våra tjänster förmǻnligare.
        </p>
        <p>
            Krendo Konsulting AB föredrar programvara med öppen källkod och gemenskapsversioner av serverprogramvara, och strävar till 
            att tillämpa molntjänster som erbjuder förmånliga tjänster med kvalitet och kost effektivitet. Programvarans uppladdning
            byggs upp med automati så att det är smidigt att installera on eventuellt flytta tjänster till alternativa molntjänster vid behov.
        </p>
    </div>

    <div class="px-3 px-md-5" *ngIf="language=='en'">
        <h1>About the company</h1>
        <p>
            Krendo Consulting Ltd is a company that specializes in software development. In the software development business today, it
            is common that consultants come from big corporations and often there are more intermediary companies between the customer
            and the consultant. Krendo Consulting, on the other hand, is a small company that is not burdened by similar expenses and 
            can therefore provide a cost-effective service.
        </p>
        <p>
            Krendo Consulting LTD prefers open source software and community distributions of server software and strives to utilize
            cloud service providers offering inexpensive service with acceptable quality. Software deployment is built up with 
            deployment automation tools, which makes installations and updates cost-effective and also makes it easier to migrate 
            to other cloud service providers if need arises.
        </p>
    </div>
</section>
<!-- Technology stack -->
<section>
    <div class="px-3 px-md-5">
        <div *ngIf="language=='fi'">
            <h2>Teknologiat</h2>
            <p>
                Avoimen lähdekoodin maailmassa koko infrastruktuurin pystyy rakentamaan ilman lisenssimaksuja. Tämän vastapainoksi
                tosin tuotetukea ei ole saatavilla valmistajan puolelta, mikä merkitsee sitä, että konsulttien pitää osata itsenäisesti 
                tehdä käyttötapauksen vaatimia konfiguraatioita ja ennen kaikkea huolehtia asennuksen tietoturvasta. 
            </p>
        </div>
        <div *ngIf="language=='sv'">
            <h1>Teknologier</h1>
            <p>
                +Krendo Konsulting AB föredrar programvara med öppen källkod och gemenskapsversioner av serverprogramvara.
                Vi strävar till att tillämpa förmånliga molntjänster med god kvalitet och kostnadseffektivitet. Uppladdningen av programvaror 
                sker med automatik, vilket vid behov medför smidighet  att flytta sin programverksamhet till alternativa molntjänster.

                öppna källkodsvärlden kan hela IT-infrastrukturer byggas upp av system utan licenskostnader, vilket medför stora inbesparingar
                för ert företag. Å andra sidan betyder detta att tillverkaren inte stöder användningen, varför konsulterna måste ha bra kunskap
                 vid uppbyggnad avsystemet och de måste kunna klara av eventuella problemsituationer. Framför allt är det viktigt att ta hand 
                 om datasäkerheten. 
            </p>
        </div>
        <div *ngIf="language=='en'">
            <h1>Technologies</h1>
            <p>
                In the Open Source world it is possible to build the entire infrastrukture from software that does not require license 
                payments. The downside is that there is no vendor-provided product support. This means that the consultants must have
                good knowledge in how toset up the systems and manage problem situations. It is crucial to manage information security 
                properly. 
            </p>
        </div>
        <ul *ngIf="language=='fi'">
            <li>Linux pohjaiset käyttöjärjestelmät, kuten Ubuntu, Red Hat.</li>
            <li>Asennusautomaatio, kuten. Ansible, Jenkins CI.</li>
            <li>Java-pohjaiseet sovelluskehykset, kuten Spring Boot.</li>
            <li>Osaaminen JavaScript-pohjaisissa sovelluskehyksissä.</li>
            <li>HTML/CSS -pohjaiset käyttöliittymät.</li>
            <li>SQL ja LDAP -pohjaiset palvelut, kuten PostgreSQL, MariaDB, SQL Server, Apache Directory.</li>
            <li>Automaattiset haavoittuvuus-skannerit, kuten OWASP Dependency Checker, OpenSCAP.</li>
            <li>Serverless -teknologiat, kuten Docker, ja Kubernetes.</li>
            <li>HTTP palvelimet ja käänteisvälityspalvelimet, kuten Apache HTTPD, NGINX, HA-Proxy.</li>
            <li>Kokemusta identiteetin ja pääsynhallinnan standardeista ja toteutuksesta.</li>
            <li>Tiedon muunnosteknologiat, kuten XSLT.</li>
            <li>Yli 20 kokemus sovelluskehityksestä.</li>
        </ul>
        <ul *ngIf="language=='sv'">
            <li>Operativsystem baserade på Linux, såsom Ubuntu, Red Hat.</li>
            <li>Automatisk installering, t.ex. med Ansible och Jenkins CI.</li>
            <li>Tillämpningsramer omkring Java, såson Spring Boot.</li>
            <li>Java och JavaScript baserade användardgränssnitt som bygger på CSS and HTML.</li>
            <li>SQL och LDAP baserade tillämningar, såsom PostgreSQL oc MariaDB, eller t.ex. Apache Directory.</li>
            <li>Automatiskt skanning av sårbarheter med t.ex. OWASP Dependency Checker och OpenSCAP.</li>
            <li>Serverless teknologier, såsom Docker, eller Kubernetes.</li>
            <li>HTTP Servers och omvända proxy servers såsom Apache HTTPD, NGINX och HAProxy.</li>
            <li>Erfarenhet ab Identity and Access Management standards and implementation.</li>
            <li>Data transformering med teknolgier såsom XSLT.</li>
            <li>Över 20 års erfarenhet i mjukvaruutveckling.</li>
        </ul>
        <ul *ngIf="language=='en'">
            <li>Linux based operating systems, such as Ubuntu, Red Hat.</li>
            <li>Deployment automation, e.g. Ansible, Jenkins CI, SonarQube.</li>
            <li>Java based application backend frameworks, such as Spring Boot.</li>
            <li>Java and JavaScript based application frontend frameworks with underlying CSS and HTML.</li>
            <li>SQL and LDAP based Storage backends such as PostgreSQL, MariaDB, SQL Server, Active Directory, Apache Directory.</li>
            <li>Automatic vulnerability analysis with e.g. OWASP Dependency Checker, OpenSCAP.</li>
            <li>Serverless technologies, such as Docker, Docker Swarm and Kubernetes.</li>
            <li>HTTP Servers and Reverse Proxies such as Apache HTTPD, NGINX, HA-Proxy.</li>
            <li>Experience in Identity and Access Management standards and implementation.</li>
            <li>Data transformations using technologies like XSLT.</li>
            <li>More than 20 years experience in software development.</li>
        </ul>
    </div>
</section>
<!-- Values -->
<section>
    <div class="px-3 px-md-5" *ngIf="language=='fi'">
        <h2>Yrityksen arvot</h2>
        <ul>
            <li>
                <b>Sijoitetun pääoman tuotto:</b> ohjelmistokehitys on investointi. Krendo Consultingin arvo on, että tehdyt ratkaisut toimivat
                laadukkaasti alusta alkaen, jolloin asiakas pääsee nauttimaan tuotteiden tuomasta hyödystä. Läheisen yhteistyön avulla asiakkaan
                kanssa varmistuu, että tuote vastaa asiakkaan tarpeita.
            </li>
            <li>
                <b>Tuottavuus:</b> Krendo Consultingin mielestä ihminen ei ole robotti, eikä hänen kuulu tehdä robotin työtä. Sitävastoin
                robotin työn tekee tehokkaimmin robotti. Siksi Krendo Consulting pyrkii automatisoimaan tällaiset toistuvat prosessit, jotta
                ihminen pystyy panostamaan siihen työhön missä hän tuottaa eniten arvoa. 
            </li>
        </ul>
    </div>
    <div class="px-3 px-md-5" *ngIf="language=='sv'">
        <h2>Företagets värden</h2>
        <ul>
            <li>
                <b>Avkastning på investering hos kunden: </b> programvaruutveckling är en investering. Krendo Consultings värde är, att den tillverkade
                programvaran fungerar med kvalitet från första början så att kunden kan njuta av värdet som produkten ger. Tack vare
                nära samarbete med er organisation, dvs slutkunden försakrar vi att produkten motsvarar ert behov.
            </li>
            <li>
                <b>Produktivitet hos kunded: </b> Krendo Consulting tycker att en människa inte är en robot, och därmed borde inte människan göra arbete 
                som tillhör en robot. En robot är den bästa arbetare för rutinjobb, som kan automatiseras. Därför strävar Krendo Consulting till att automatisera
                 upprepande prosesser, så att människan själv kan koncentrera sin arbetstid på sådant, som mera utnyttjar den mänskliga kompetensen.
            </li>
        </ul>
    </div>
    <div class="px-3 px-md-5" *ngIf="language=='en'">
        <h2>Company values</h2>
        <ul>
            <li>
                <b>Return on investment:</b> Software development is an investment. Krendo Consulting's values include that the produced
                software provides quality from the beginning of its lifecycle, so the customer can enjoy the value of the product. With
                close collaboration with the customer, the product is ensured to provide the specific value that is required. 
            </li>
            <li>
                <b>Productivity:</b> Krendo Consulting considers that people are not robots and therefore should not be tasked to do the 
                work of robots. A robot, on the other hand, is the ideal employee for a robot's work. Therefore Krendo Consulting strives
                to automate any repeating processes, so that people can focus on such work that produces most value.
            </li>
        </ul>
    </div>
</section>
<!-- Technology stack -->
<section>
    <div class="px-3 px-md-5">
        <div *ngIf="language=='fi'">
            <h2>Yhteystiedot</h2>
            <p>
                Yhteystiedot löytyvät sivun yllä olevasta valikosta CAPTCHAn takaa.
            </p>
        </div>
        <div *ngIf="language=='sv'">
            <h2>Kontaktuppgifter</h2>
            <p>
                Kontaktuppgifterna kan hittas i sidans navigation bakom CAPTCHA.
            </p>
        </div>
        <div *ngIf="language=='en'">
            <h2>Contact details</h2>
            <p>
                Contact details are in the navigation bar, available only after solving the CAPTCHA.
            </p>
        </div>
    </div>
</section>
<!-- data protection policy-->
<!-- TODO -->
